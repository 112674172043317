<template>
  <v-card class="mx-auto login-card" :loading="loading" :outlined="$vuetify.breakpoint.smAndUp" :flat="$vuetify.breakpoint.xsOnly">
    <v-img class="mx-auto logo" :src="logo"></v-img>
    <div class="login-title">
       Iniciar sesión
    </div>
    <v-form ref="form">
      <v-card-text class="login-card-text">
        <v-row justify="center" no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="user.email"
              label="Correo"
              :rules="[isRequired, isEmail]"
              :error-messages="emailServerMessage"
              @input="resetEmailServerMessage"
              outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="user.password"
              label="Contraseña"
              :rules="[isRequired]"
              :type="passwordIsVisible ? 'text' : 'password'"
              :error-messages="passwordServerMessage"
              @input="resetPasswordServerMessage"
              outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="text-left show-password-column">
            <v-checkbox label="Mostrar contraseña" @change="showPassword">
            </v-checkbox>
          </v-col>
          <v-col cols="12" class="text-left actions-column">
            <v-row justify="center" no-gutters>
              <v-col>
                <v-btn class="text-none recover-password-button" text color="primary" @click="goToForgotPassword">
                  Recuperar contraseña
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn class="text-none" depressed color="primary" :loading="loading" @click="login">
                  Ingresar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import logo from '@/assets/logo.jpg'
import { isRequired, isEmail } from '@/shared/validations'
import { STATUS_UNAUTHORIZED, STATUS_NOT_FOUND } from '@/shared/httpStatuses'

export default {
  mixins: [navigationMixin],
  data () {
    return {
      logo,
      previousRoute: null,
      redirect: null,
      user: {
        email: '',
        password: ''
      },
      emailServerMessage: null,
      passwordServerMessage: null,
      passwordIsVisible: false
    }
  },
  computed: {
    ...mapState('shared', ['error', 'loading'])
  },
  methods: {
    isRequired,
    isEmail,
    ...mapActions('auth', ['signIn']),
    ...mapActions('notification', ['showNotification']),
    showPassword (isVisible) {
      this.passwordIsVisible = isVisible
    },
    resetEmailServerMessage () {
      this.emailServerMessage = null
    },
    resetPasswordServerMessage () {
      this.passwordServerMessage = null
    },
    setError () {
      if (this.error.code === STATUS_NOT_FOUND) {
        this.emailServerMessage = 'No se ha podido encontrar tu cuenta.'
        return
      }

      if (this.error.code === STATUS_UNAUTHORIZED) {
        this.passwordServerMessage = 'La contraseña es incorrecta.'
        return
      }

      this.showNotification({ message: this.error.message })
    },
    goToForgotPassword () {
      const queryParams = {}
      if (this.redirect) {
        queryParams.redirect = this.redirect
      }

      this.goToRouteByName('forgot-password', {}, queryParams)
    },
    async login () {
      if (!this.$refs.form.validate()) {
        return
      }

      await this.signIn(this.user)
      if (this.error) {
        this.setError()
        return
      }

      this.redirectPage()
    },
    redirectPage () {
      if (this.redirect) {
        this.goTo(this.redirect)
        return
      }

      if (this.previousRoute !== null && this.previousRoute.name !== null && !this.previousRoute.meta.hideInSession) {
        this.goToRouteByName(this.previousRoute.name, this.previousRoute.params)
        return
      }

      this.goToRouteByName('home')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.previousRoute = from
    })
  },
  mounted () {
    this.redirect = this.$route.query.redirect
  }
}
</script>
<style scoped>
.login-card {
  width: 28rem;
  min-height: 31.375rem;
}
.logo {
  margin-top: 2.25rem;
  margin-bottom: 1rem;
  width: 5.625rem;
}
.login-title {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}
.login-card-text {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
}
.show-password-column {
  margin-top: -1rem;
}
.actions-column {
  margin-top: 1.5rem;
}
.recover-password-button {
  margin-left: -1rem;
}
/* 599px */
@media only screen and (max-width: 37.438rem) {
  .logo {
    margin-top: 1.5rem;
  }
  .login-card-text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
</style>
